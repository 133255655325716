import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Group, Overlay, Select, Stack, Text, TextInput } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useServerConstants } from "hooks/useServerConstants";
import { Controller, useFormContext } from "react-hook-form";

const genderList = [
	{ value: "male", label: "Male" },
	{ value: "female", label: "Female" },
];

const documentTypeList = [
	{ value: "PASSPORT", label: "Passport" },
	{ value: "ID_CARD", label: "Id card" },
	{ value: "DRIVERS_LICENSE", label: "Drivers License" },
	{ value: "RESIDENCE_PERMIT", label: "Residence Permit" },
];

type ComponentProps = {
	isVerified?: boolean;
	onVerification?: boolean;
};

export const KycForm = ({ isVerified, onVerification }: ComponentProps) => {
	const { control } = useFormContext();
	const { Country } = useServerConstants();

	return (
		<Root style={{ position: "relative" }}>
			{isVerified && (
				<Overlay
					opacity={1}
					zIndex={5}
					style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
					sx={{
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: "#29CCB1",
						backgroundColor: "rgba(234, 250, 246, 0.98)",
						borderRadius: 8,
					}}
				>
					<Text weight={600} color={`#12b886`} size={"lg"}>
						Your account is already verified
					</Text>
				</Overlay>
			)}

			{onVerification && (
				<Overlay
					opacity={1}
					zIndex={5}
					style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
					sx={{
						borderWidth: 1,
						borderStyle: "solid",
						borderColor: "#295dcc",
						backgroundColor: "rgba(234, 250, 246, 0.98)",
						borderRadius: 8,
					}}
				>
					<Text weight={600} color={`#295dcc`} size={"lg"}>
						Your account is awaiting verification
					</Text>
				</Overlay>
			)}

			<Group>
				<StylesController
					name="firstName"
					control={control}
					render={({ field, fieldState }) => (
						<TextField
							label="First name"
							placeholder="Enter your first name"
							variant="filled"
							required
							error={fieldState.error?.message}
							classNames={{ input: "input-field" }}
							{...field}
						/>
					)}
				/>

				<StylesController
					name="secondName"
					control={control}
					render={({ field, fieldState }) => (
						<TextField
							label="Second name"
							placeholder="Enter second name"
							variant="filled"
							required
							error={fieldState.error?.message}
							classNames={{ input: "input-field" }}
							{...field}
						/>
					)}
				/>
			</Group>

			<Group>
				<StylesController
					name="gender"
					control={control}
					render={({ field, fieldState }) => (
						<SelectField
							label="Gender"
							placeholder="Pick your gender"
							variant="filled"
							searchable
							required
							data={genderList}
							error={fieldState.error?.message}
							classNames={{ input: "input-field" }}
							{...field}
						/>
					)}
				/>

				<StylesController
					name="dateOfBirth"
					control={control}
					render={({ field, fieldState }) => (
						<DatePickField
							label="Date of birth"
							placeholder="Pick date"
							variant="filled"
							required
							error={fieldState.error?.message}
							classNames={{ input: "input-field" }}
							{...field}
						/>
					)}
				/>
			</Group>

			<Group>
				<StylesController
					name="typeDocument"
					control={control}
					render={({ field, fieldState }) => (
						<SelectField
							label="Document type"
							placeholder="Document type"
							variant="filled"
							searchable
							required
							data={documentTypeList}
							error={fieldState.error?.message}
							classNames={{ input: "input-field" }}
							{...field}
						/>
					)}
				/>

				<StylesController
					name="countryDocument"
					control={control}
					render={({ field, fieldState }) => (
						<SelectField
							label="Country"
							placeholder="Pick country"
							variant="filled"
							searchable
							required
							error={fieldState.error?.message}
							data={Country.map((item) => ({
								value: String(item.code),
								label: item.name,
							}))}
							classNames={{ input: "input-field" }}
							{...field}
						/>
					)}
				/>
			</Group>

			<Group>
				<StylesController
					name="numberDocument"
					control={control}
					render={({ field, fieldState }) => (
						<TextField
							label="Document number"
							placeholder="Enter document number"
							variant="filled"
							required
							error={fieldState.error?.message}
							classNames={{ input: "input-field" }}
							{...field}
						/>
					)}
				/>

				<StylesController
					name="idNumber"
					control={control}
					render={({ field, fieldState }) => (
						<TextField
							label="Id number"
							placeholder="Enter id number"
							variant="filled"
							required
							error={fieldState.error?.message}
							classNames={{ input: "input-field" }}
							{...field}
						/>
					)}
				/>
			</Group>
		</Root>
	);
};

const Root = styled(Stack)`
	margin-top: 20px;
	background: #ffffff;
	border: 1px solid #e9ecef;
	border-radius: 8px;
	padding: 20px;
	gap: 20px;
`;

const InputCSS = css`
	display: flex;
	font-size: 14px;
	font-weight: 400;
	background: #fcfdfe;
	border: 1px solid #f0f1f7;
	color: #9fa2b4;
	line-height: 1.5;
	min-height: 42px;
	outline: none;
	padding: 10px 16px;
	width: 100%;
	transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out;

	&:hover,
	&:focus {
		color: #252733;
		border-color: #0095e5;
	}

	&::placeholder {
		color: rgb(75, 80, 109, 0.5);
	}
`;

const TextField = styled(TextInput)`
	flex: 1;
	margin: 0;
	position: relative;
	mantine-TextInput-wrapper {
		margin: 0;
	}
	.mantine-TextInput-error {
		position: absolute;
		left: 0;
		bottom: -18px;
	}
	& .input-field {
		${InputCSS};
	}
`;
const StylesController = styled(Controller)`
	margin: 0;
`;

const SelectField = styled(Select)`
	flex: 1;

	& .input-field {
		${InputCSS};
		cursor: pointer;
	}
`;

const DatePickField = styled(DatePickerInput as any)`
	flex: 1;

	& .input-field {
		${InputCSS};
	}
`;
