import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { ActionIcon, Avatar, Group, Stack, Text } from '@mantine/core';
import { TCollectableCard } from '../types';
import useMutationData from "../../../hooks/useMutationData";
import {showNotification} from "@mantine/notifications";
import QueryKeys from 'constants/query-keys';

// Props
type ComponentProps = {
	card: TCollectableCard;
};

// Component
export default function ListItem({ card }: ComponentProps) {
	const navigate = useNavigate();

	const removeRequest = useMutationData({
        url: () => `/admin/collectible-cards/${card.id}`,
        method: 'delete',
        queryKeys: {
            invalidate: [
                { queryKey: [QueryKeys.PLATFORM_COLLECTABLE_CARDS] },
                { queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARDS] },
            ],
            remove: [
                [QueryKeys.PLATFORM_COLLECTABLE_CARD, { cardId: card.id }],
            ]
        },
        onSuccess: async () => {
            showNotification({
                autoClose: 3000,
                title: 'Success!',
                message: 'Collectable card successfully deleted!',
                color: 'green',
            });
        }
    })

	return (
		<Root position="apart" px={16} py={8}>
            <Group position="left" align={"center"} spacing={12}>
				<Avatar size={32} radius={'xl'} src={card.imagURL} alt={card.name} />
				<Stack spacing={2}>
					<Text size={14} weight={500} lh={1.5} tt={"capitalize"}>{card.name}</Text>
					<Text size={12} weight={400} lh={1.5} color="dark.2">{card.description}</Text>
				</Stack>
            </Group>

            <Group position="right" spacing={8}>
                <ActionIcon
                    disabled={removeRequest.isLoading}
                    variant="subtle"
                    color="gray.5"
                    onClick={() => navigate(`/platform/collectable-cards/${card.id}`)}
                    sx={(theme) => ({
                        background: "#FCFDFE",
                        border: "1px solid #F1F3F5",
                        borderRadius: "6px",
                        color: theme.colors.dark[2],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.dark[2],
                        },
                    })}
                >
                    <Icon icon={"fluent:edit-16-regular"} height={14} />
                </ActionIcon>
                <ActionIcon
                    variant={"subtle"}
                    onClick={() => removeRequest.mutate(card.id)}
                    loading={removeRequest.isLoading}
                    sx={(theme) => ({
                        background: "#FCFDFE",
                        border: "1px solid #F1F3F5",
                        borderRadius: "6px",
                        color: theme.colors.red[5],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.red[5],
                        },
                    })}
                >
                    <Icon icon={"fluent:delete-16-regular"} height={14} />
                </ActionIcon>
            </Group>
        </Root>
	)
}

const Root = styled(Group)`
  &:not(:last-child) {
    border-bottom: 1px solid #f8f9fa;
  }
`;
