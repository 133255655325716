export enum ModalTrigger {
    ADD_GAME,
    SHARE_GAME,
    ADD_CARDS,
    ADD_WORKING_DATE,
    ADD_MERCHANT_TARGET_GROUP,
    EDIT_MERCHANT_TARGET_GROUP,
    ADD_PLATFORM_TARGET_GROUP,
    EDIT_PLATFORM_TARGET_GROUP,
    // COLLECTABLE CARDS
    SHARE_COLLECTABLE_CARD_TO_MERCHANT,
    SHARE_COLLECTABLE_CARD_TO_CONTENT
}
