import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type MerchantTargetGroupContent = {
    content_id: number;
    preferences: number[];
    age_groups: number[];
}

export type MerchantTargetGroup = {
    merchant_id: number;
    target_contents: MerchantTargetGroupContent[];
}


interface IMerchantTargetStore {
    targets: MerchantTargetGroup[];

    syncMerchantContents: (merchant_id: number, content_ids: number[]) => void;

    addMerchant: (merchant_id: number) => void;
    removeMerchant: (merchant_id: number) => void;

    setMerchantContent: (merchant_id: number, content: MerchantTargetGroupContent) => void;
    removeMerchantContent: (merchant_id: number, content_id: number) => void;
}

const InitialState: Pick<IMerchantTargetStore, 'targets'> = {
    targets: localStorage.getItem('MERCHANT_TARGET_STORE') ? JSON.parse(localStorage.getItem('MERCHANT_TARGET_STORE')!) : [],
};

export const useMerchantTargetStore = create<IMerchantTargetStore>()(
    devtools(
        (set, get) => ({
            ...InitialState,

            syncMerchantContents: (merchant_id, content_ids) => {
                const local_saved = localStorage.getItem('MERCHANT_TARGET_STORE');
                if (!local_saved) return;

                let local_targets = JSON.parse(local_saved) as MerchantTargetGroup[];
                const merchantTarget = local_targets.find(({ merchant_id: id }) => id === merchant_id);

                for (const item of merchantTarget?.target_contents ?? []) {
                    if (content_ids.includes(item.content_id)) continue;
                    get().removeMerchantContent(merchant_id, item.content_id);
                }
            },

            addMerchant: (merchant_id) => {
                const targets = get().targets;

                if (targets.some(({ merchant_id: id }) => id === merchant_id)) return;

                const new_targets = [...targets, { merchant_id: merchant_id, target_contents: [] }] as MerchantTargetGroup[];
                localStorage.setItem('MERCHANT_TARGET_STORE', JSON.stringify(new_targets));
                set({ targets: new_targets });
            },
            removeMerchant: (merchant_id) =>{
                const targets = get().targets;

                if (!targets.some(({ merchant_id: id }) => id === merchant_id)) return;

                const new_targets = targets.filter(({ merchant_id: id }) => id !== merchant_id);
                localStorage.setItem('MERCHANT_TARGET_STORE', JSON.stringify(new_targets));
                set({ targets: new_targets });
            },

            setMerchantContent: (merchant_id, content) => {
                const targets = get().targets;

                if (!targets.some((item) => item.merchant_id === merchant_id)) return;

                const updated_targets = targets.map((item) => {
                    if (item.merchant_id !== merchant_id) return item;
                    return { ...item, target_contents: [...item.target_contents, content] };
                });
                
                localStorage.setItem('MERCHANT_TARGET_STORE', JSON.stringify(updated_targets));
                set({ targets: updated_targets });
            },
            removeMerchantContent: (merchant_id, content_id) => {
                const targets = get().targets;

                if (!targets.some((item) => item.merchant_id === merchant_id && item.target_contents.some(item => item.content_id === content_id))) return;

                const updated_targets = targets.map((item) => {
                    if (item.merchant_id !== merchant_id || !item.target_contents.some(i => i.content_id === content_id)) return item;
                    return { ...item, target_contents: item.target_contents.filter(i => i.content_id !== content_id) };
                });

                localStorage.setItem('MERCHANT_TARGET_STORE', JSON.stringify(updated_targets));
                set({ targets: updated_targets });
            }
        }),
        { name: 'Merchant Target' },
    ),
);
