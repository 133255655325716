import { Button, Card, Group, MultiSelect, NumberInput, Stack, Text, TextInput } from "@mantine/core";
import Tabs from "components/Tabs";
import { useFormContext } from "react-hook-form";

const tabs = [
    { label: "Collectible Cards", value: "collectible-cards" }
]

const inputStyles = {
    label: {
        marginBottom: 12,
    },
    input: {
        background: '#FCFDFE',
        border: '1px solid #F1F3F5',
        borderRadius: '4px',
        "::placeholder": {
            color: '#9FA2B4'
        }
    }
}

export default function MerchantGameDetails() {
    const form = useFormContext()

    return (
        <Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
            <Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
                <Group position="apart">
                    <Text size={16} weight={600} lh={1.5}>
                        Overview
                    </Text>
                    <Group>
                        <Button loading={form.formState.isSubmitting} disabled={!form.formState.isDirty || !form.formState.isValid} type="submit" variant="filled" h={28} px={12} py={4} color="dark.8" radius={4}>
                            <Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>UPDATE</Text>
                        </Button>
                    </Group>
                </Group>
            </Card.Section>
            <Card.Section inheritPadding p={24}>
                <Stack spacing={24}>
                    <Group spacing={24} grow>
                        <TextInput
                            {...form.register('name')}
                            label="Name"
                            placeholder="Enter game name"
                            readOnly
                            disabled
                            required
                            styles={inputStyles}
                        />
                        <NumberInput 
                            label="Max Attempts"
                            placeholder="Enter max attempts"
                            required
                            defaultValue={1}
                            min={0}
                            max={100}
                            step={1}
                            styles={inputStyles}
                        />
                    </Group>

                    <Group spacing={24} grow>
                        <NumberInput
                            label="Spawn Frequency"
                            placeholder="Enter spawn frequency"
                            parser={(value) => value.replace(/\D/g, '')}
                            defaultValue={10}
                            formatter={(value) => `${value}%`}
                            required
                            styles={inputStyles}
                        />
                        <MultiSelect
                            label="Target Audience"
                            data={[
                                { value: '1', label: '18-24' },
                                { value: '2', label: '25-34' },
                                { value: '3', label: '35-44' },
                                { value: '4', label: '45-54' },
                                { value: '5', label: '55-64' },
                                { value: '6', label: '65+' },
                            ]}
                            value={['1']}
                            required
                            withinPortal
                            styles={inputStyles}
                        />
                    </Group>
                </Stack>
            </Card.Section>
            <Card.Section inheritPadding px={12} sx={{ borderTop: "1px solid #f8f9fa" }}>
                <Tabs queryKey="tab" defaultValue={tabs[0].value} tabs={tabs} />
            </Card.Section>
        </Card>
    )
}