import { Button, Card, Group, Stack, Text } from "@mantine/core";
import Modal from "features/Modal";
import { ModalTrigger } from "features/Modal/constants";
import { Fragment, useMemo } from "react";
import AddCardModal from "./AddCardModal";
import { useModalStore } from "features/Modal/store/modal.store";
import { useSharingGameStore } from "store/game-sharing.store";
import { useParams } from "react-router-dom";

import ListItem from "./ListItem";
import usePaginatedData from "hooks/usePaginatedData";
import { TCollectableCard } from "features/CollectionCardsList/types";

export default function SharedMerchants() {
    const { id } = useParams<{ id: string }>();

    const openModal = useModalStore(state => state.setOpen);

    const sharedGame = useSharingGameStore(state => state.sharedGames.find(({ game_id }) => game_id === Number(id))!);
    const { items } = usePaginatedData<TCollectableCard>({
        path: "/admin/collectible-cards",
        defaultLimit: 1000,
        queryKey: ["COLLECTABLE_CARDS"],
    });

    const cards = useMemo(() => {
        return items.filter(item => sharedGame.collectible_card_ids.includes(item.id))
    }, [items, sharedGame.collectible_card_ids]);

    return (
        <Fragment>
            <Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
                <Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
                    <Group position="apart">
                        <Text size={16} weight={600} lh={1.5}>
                            Collectible Cards
                        </Text>
                        <Group>
                            <Button type="submit" variant="filled" h={28} px={12} py={4} color="dark.8" radius={4} onClick={() => openModal({
                                title: 'Add Collectible Cards',
                                trigger: ModalTrigger.ADD_CARDS,
                            })}>
                                <Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>ADD CARDS</Text>
                            </Button>
                        </Group>
                    </Group>
                </Card.Section>
                <Card.Section inheritPadding p={0} pb={2}>
                    <Stack spacing={0}>
                        {cards.map((item) => (
                            <ListItem key={item.id} card={item} />
                        ))}

                        {cards.length === 0 && (
                            <Group p={24} align="center" position="center">
                                <Text size={"14px"} weight={400} color="gray.6" lh={1.5}>Collectible Card list is empty</Text>
                            </Group>
                        )}
                    </Stack>
                </Card.Section>
            </Card>

            <Modal triggers={[ModalTrigger.ADD_CARDS]}>
                <AddCardModal />
            </Modal>

        </Fragment>
    )
}