import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Group, MultiSelect, Stack, Text } from "@mantine/core";
import { TCollectableCard } from "features/CollectionCardsList/types";
import { useModalStore } from "features/Modal/store/modal.store";
import usePaginatedData from "hooks/usePaginatedData";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSharingGameStore } from "store/game-sharing.store";
import * as yup from 'yup';

type FormState = {
	collectible_card_ids: number[];
};

export default function AddCardModal() {
	const { id } = useParams<{ id: string }>();
	const [loading, setLoading] = useState(false);

	const sharingData = useSharingGameStore(state => state.sharedGames.find(({ game_id }) => game_id === Number(id))!);
	const addCollectibleCards = useSharingGameStore(state => state.addCollectibleCards);
	const close = useModalStore(state => state.setClose);

	const { items: cards } = usePaginatedData<TCollectableCard>({
        path: "/admin/collectible-cards",
        defaultLimit: 1000,
        queryKey: ["COLLECTABLE_CARDS"],
    });

	// Form Implementation
	const form = useForm<FormState>({
		mode: 'onChange',
		resolver: yupResolver(yup.object({
			collectible_card_ids: yup.array().of(yup.number()).required(),
		})),
		defaultValues: {
			collectible_card_ids: []
		}
	});

	const availableCards = useMemo(() => {
		return cards
			.filter(card => !sharingData.collectible_card_ids.includes(card.id))
			.map(item => ({
				label: item.name,
				value: String(item.id),
			}));
	}, [cards, sharingData.collectible_card_ids]);

	const onShare = form.handleSubmit((values) => {
		setLoading(true);
		const timeout = setTimeout(() => {
			addCollectibleCards(Number(id), values.collectible_card_ids);
			close();
			setLoading(false);
			form.reset();
			clearTimeout(timeout);
		}, 1000);
	});

	return (
		<form onSubmit={onShare}>
			<Stack px={24} pb={24} spacing={0}>
				<Stack spacing={8}>
					<Controller
						name='collectible_card_ids'
						control={form.control}
						render={({ field }) => (
							<MultiSelect {...field}
								data={availableCards}
								placeholder={'Select collectible cards'}
								nothingFound={'Not found'}
								variant={'filled'}
								clearable
								searchable
								value={field.value.map(item => String(item))}
								onChange={values => field.onChange(values.map(item => Number(item)))}
								withinPortal
								clearButtonProps={{
									'aria-label': 'Clear input',
								}}
								styles={{
									values: {
										marginLeft: -5,
									},
									value: {
										backgroundColor: '#F1F3F5',
										border: '1px solid #F1F3F5',
										borderRadius: '3px',
										color: '#868E96',
										fontSize: '12px',
										fontWeight: 400,
									},
									defaultValueRemove: {
										color: '#868E96',
										width: 18,
										height: 18,
										minHeight: 18,
										minWidth: 18,
									},
									label: {
										marginBottom: 12,
									},
									input: {
										background: '#FCFDFE',
										border: '1px solid #F1F3F5',
										borderRadius: '4px',
										paddingTop: 3,
										paddingBottom: 3,
										"::placeholder": {
											color: '#9FA2B4'
										}
									}
								}}
							/>
						)}
					/>
				</Stack>
				<Group grow align={'center'} mt={24}>
					<Button type='submit' loading={loading} variant="filled" h={36} px={12} py={4} color="teal.7" radius={4} >
						<Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>SUBMIT</Text>
					</Button>
				</Group>
			</Stack>
		</form>
	)
}