import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { Button, Group, NumberInput, Select, Stack, Text, Tooltip } from "@mantine/core";
import QueryKeys from "constants/query-keys";
import type { TCollectableCard } from "features/CollectionCardsList/types";
import { useMerchantNavigation } from "hooks/useMerchantNavigation";
import useMutationData from "hooks/useMutationData";
import usePaginatedData from "hooks/usePaginatedData";
import useSelectData from "hooks/useSelectData";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import CollectableItem from "./CollectableItem";
import { useEventCards } from "../store/event-cards.store";
import { showNotification } from "@mantine/notifications";
import CollectibleCard from "./CollectibleCard";
import { useQuery } from "react-query";
import { api } from "utils/api";

export default function CollectableSelector() {
    const { id } = useMerchantNavigation();
    const { id: eventId } = useParams();

    const addCard = useEventCards(state => state.addCard);
    const resetStore = useEventCards(state => state.resetStore);
    const cardsList = useEventCards(state => state.cards);
    const [selectedId, setSelectedId] = useState<any[] | null>(null);
    const [frequency, setFrequency] = useState<number | undefined>(undefined);
    const [maxAttempts, setMaxAttempts] = useState<number | undefined>(undefined);

    const { items: allCards } = usePaginatedData<TCollectableCard>({
        path: `/admin/merchants/${id}/collectible-cards`,
        defaultLimit: 1000,
        queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARDS],
    })

    const cards = useSelectData(allCards, {
        labelKey: "name",
    });

    // Actions
    const setCardMutation = useMutationData({
        url: () => `/admin/merchants/${id}/events/${eventId}/events`,
        method: 'post',
        queryKeys: {
            invalidate: [
                { queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARD, { merchantId: Number(id), cardId: Number(selectedId) }] },
            ],
        }
    })

    const handleAddCard = () => {
        if (!selectedId || !frequency || !maxAttempts) {
            showNotification({
                autoClose: 3000,
                title: "Warning!",
                message: "Please fill all card fields",
                color: "orange",
            });
            return;
        }
        const newCard: TStorageCollectibleCard = {
            id: selectedId[0],
            name: selectedId[1],
            frequencyOccurrence: frequency,
            maxAttempts: maxAttempts,
        }
        addCard(newCard);
        setSelectedId(null);
    }

    const hanldeSelectCard = (value: any, cards: any) => {
        console.log(value, cards);
        const cardName = cards.filter((card: any) => card.value == value)[0];
        console.log(cardName.label)
        setSelectedId([value, cardName.label]);
    }

    // const { items: eventCards, isLoading } = usePaginatedData({
    //     path: `/admin/merchants/${id}/events/${eventId}/collectible-cards`,
    //     defaultLimit: 1000,
    //     queryKey: [QueryKeys.CARDS_FOR_EVENT],
    // });

    const { data: eventCards, isLoading } = useQuery({
        queryFn: () => eventId ? api.get(`/admin/merchants/${id}/events/${eventId}/collectible-cards`) : undefined,
        queryKey: [QueryKeys.CARDS_FOR_EVENT],
    });

    useEffect(() => {
        resetStore();
        if (isLoading) return;
        eventCards?.data?.data?.items?.map((card: any) => {
            addCard({
                id: card.collectibleCardId,
                name: card.CollectibleCard.name,
                frequencyOccurrence: card.frequencyOccurrence,
                maxAttempts: card.maxAttempts,
                settingsId: card.id,
            });
        });
    }, [eventCards, eventId]);

    return (
        <Stack spacing={0}>
            <Group position="left">
                <Text size="md" weight="bold">
                    Collectable Cards
                </Text>

                <Tooltip multiline width={220} withArrow label="Receive a random collectible card for visiting all locations.">
                    <Icon icon="material-symbols:info-outline" color="#f55200" />
                </Tooltip>
            </Group>
            <Group mb={8} position="apart">

                <SelectField
                    label="Card"
                    placeholder="Pick one"
                    searchable
                    nothingFound="No options"
                    data={cards.items}
                    classNames={{ input: "input-field" }}
                    onChange={(value) => hanldeSelectCard(value, cards.items)}
                    value={selectedId ? String(selectedId[0]) : null}
                    sx={{ width: "100%", maxWidth: 300 }}
                    withAsterisk
                />

                <NumberInput
                    label="Frequency Occurrence"
                    hideControls
                    min={0}
                    max={1}
                    precision={2}
                    value={frequency ? frequency : undefined}
                    onChange={(value: number) => setFrequency(value)}
                    withAsterisk
                />

                <NumberInput
                    label="Max attempts"
                    hideControls
                    value={maxAttempts ? maxAttempts : undefined}
                    onChange={(value: number) => setMaxAttempts(value)}
                    withAsterisk
                />

                <Button
                    leftIcon={<Icon icon={"fluent:add-12-filled"} height={18} />}
                    radius={"sm"}
                    color={"dark"}
                    variant={"filled"}
                    onClick={handleAddCard}
                    loading={setCardMutation.isLoading}
                    sx={{ maxWidth: 300 }}
                >
                    Add Card
                </Button>
            </Group>
            {/* Content */}
            <Stack spacing={0}>
                {isLoading
                    ? <Group grow p={"lg"}>
                        <Text size="sm" color={"dimmed"}>
                            Not found...
                        </Text>
                    </Group>
                    : cardsList?.map((card: any, index: number) => <CollectibleCard
                        key={index}
                        id={card?.id}
                        name={card?.name}
                        frequencyOccurrence={card?.frequencyOccurrence}
                        maxAttempts={card?.maxAttempts}
                        settingsId={card?.settingsId}
                        type={"events"}
                    />)
                }
            </Stack>
        </Stack>
    )
}

const SelectField = styled(Select)`
  & .input-field {
    border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
  };
`;