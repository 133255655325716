import { Card, Group, Stack, Text } from "@mantine/core";
import ContentLoader from "components/ContentLoader";
import ListItem from "features/MerchantGames/components/ListItem";
import useMerchantGames from "features/MerchantGames/hooks/useMerchantGames";
import { useMerchantNavigation } from "hooks/useMerchantNavigation";

// Games Page
export default function GamesPage() {
    const { id } = useMerchantNavigation();

    const { games, isLoading } = useMerchantGames(id)

    return (
        <Stack p={24} spacing={24}>
            <Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
                <Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
                    <Group position="apart">
                        <Text size={16} weight={600} lh={1.5}>
                            Games
                        </Text>
                        <Group></Group>
                    </Group>
                </Card.Section>
                <Card.Section inheritPadding p={0} pb={2}>
                    <Stack spacing={0}>
                        {isLoading && <ContentLoader />}

                        {games.length > 0 &&
                            games.map((item) => <ListItem key={item.id} game={item} />)}

                        {!isLoading && games.length === 0 && (
                            <Group p={24} align="center" position="center">
                                <Text size={"14px"} weight={400} color="gray.6" lh={1.5}>
                                    Games list is empty
                                </Text>
                            </Group>
                        )}
                    </Stack>
                </Card.Section>
            </Card>
        </Stack>
    );
}
