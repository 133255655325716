import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { Group, Avatar, ActionIcon, Text } from "@mantine/core";
import { Merchant } from "api/interfaces/merchants";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSharingGameStore } from "store/game-sharing.store";

type SharedItemProps = {
    merchant: Merchant;
}

export default function SharedItem({ merchant }: SharedItemProps) {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);

    const removeMerchants = useSharingGameStore(state => state.removeMerchants);

    const onUnshare = (merchantId: number) => {
        setLoading(true);
        const timeout = setTimeout(() => {
            removeMerchants(Number(id), [merchantId]);
            setLoading(false);
            clearTimeout(timeout);
        }, 1000);
    }


    return (
        <ListItem position='apart' px={16} py={8}>
            <Group key={merchant.id} position='left' align={'center'} spacing={8}>
                {merchant.logo
                    ? <Avatar size="sm" radius={'xl'} src={merchant.logo} alt="it's me" />
                    : <Avatar size="sm" radius={'xl'} color='blue'>{merchant.name[0].toUpperCase()}</Avatar>
                }
                <Text>{merchant.name}</Text>
            </Group>
            <Group position='right'>
                <ActionIcon
                    variant={'subtle'}
                    onClick={() => onUnshare(merchant.id)}
                    loading={loading}
                    sx={(theme) => ({
                        background: '#FCFDFE',
                        border: '1px solid #F1F3F5',
                        borderRadius: '6px',
                        color: theme.colors.red[5],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.red[5],
                        }
                    })}
                >
                    <Icon icon={'fluent:delete-16-regular'} height={14} />
                </ActionIcon>
            </Group>
        </ListItem>
    )
}

const ListItem = styled(Group)`
    &:not(:last-child) {
        border-bottom: 1px solid #f8f9fa;
    }
`