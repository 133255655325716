import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Group, MultiSelect, Stack, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import QueryKeys from "constants/query-keys";
import { useSearchMerchant } from "features/MerchantSelector/hooks/useSearchMerchant";
import { useModalStore } from "features/Modal/store/modal.store";
import useMutationData from "hooks/useMutationData";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import * as yup from 'yup';

type FormState = {
	merchant_ids: number[];
};

export default function ShareModal() {
	const { merchants } = useSearchMerchant();

	const { id } = useParams<{ id: string }>();
	const close = useModalStore(state => state.setClose);

	// Form Implementation
	const form = useForm<FormState>({
		mode: 'onChange',
		resolver: yupResolver(yup.object({
			merchant_ids: yup.array().of(yup.number()).required(),
		})),
		defaultValues: {
			merchant_ids: []
		}
	});

	const availableMerchants = useMemo(() => {
		return merchants
			.map(item => ({
				label: item.name,
				value: String(item.id),
			}));
	}, [merchants]);

	const shareMutation = useMutationData({
		url: () => `/admin/collectible-cards/${id}/merchants`,
		method: 'post',
		queryKeys: {
			invalidate: [
				{ queryKey: [QueryKeys.PLATFORM_COLLECTABLE_CARD, { cardId: Number(id) }] },
				{ queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARDS] },
				{ queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARD, { cardId: Number(id) }] },
			],
		},
		onSuccess: async () => {
			showNotification({
				autoClose: 3000,
				title: 'Success!',
				message: 'Collectable cards successfully shared!',
				color: 'green',
			});
		}
	})

	const onShare = form.handleSubmit(async (values) => {
		try {
			await shareMutation.mutateAsync({
				merchantIds: values.merchant_ids
			})
		} finally {
			close();
			form.reset();
		}
	});

	return (
		<form onSubmit={onShare}>
			<Stack px={24} pb={24} spacing={0}>
				<Stack spacing={8}>
					<Controller
						name='merchant_ids'
						control={form.control}
						render={({ field }) => (
							<MultiSelect {...field}
								data={availableMerchants}
								placeholder={'Select merchants'}
								nothingFound={'Not found'}
								variant={'filled'}
								clearable
								searchable
								value={field.value.map(item => String(item))}
								onChange={values => field.onChange(values.map(item => Number(item)))}
								withinPortal
								clearButtonProps={{
									'aria-label': 'Clear input',
								}}
								styles={{
									values: {
										marginLeft: -5,
									},
									value: {
										backgroundColor: '#F1F3F5',
										border: '1px solid #F1F3F5',
										borderRadius: '3px',
										color: '#868E96',
										fontSize: '12px',
										fontWeight: 400,
									},
									defaultValueRemove: {
										color: '#868E96',
										width: 18,
										height: 18,
										minHeight: 18,
										minWidth: 18,
									},
									label: {
										marginBottom: 12,
									},
									input: {
										background: '#FCFDFE',
										border: '1px solid #F1F3F5',
										borderRadius: '4px',
										paddingTop: 3,
										paddingBottom: 3,
										"::placeholder": {
											color: '#9FA2B4'
										}
									}
								}}
							/>
						)}
					/>
				</Stack>
				<Group grow align={'center'} mt={24}>
					<Button type='submit' loading={form.formState.isSubmitting} variant="filled" h={36} px={12} py={4} color="teal.7" radius={4} >
						<Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>SHARE</Text>
					</Button>
				</Group>
			</Stack>
		</form>
	)
}