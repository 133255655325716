import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import { ActionIcon, Group, NumberInput, Stack, Title } from '@mantine/core';
import { MiniGame } from 'api/interfaces/locationEvents';

// Props
type ComponentProps = {
	content: MiniGame;
	onRemove: () => void;
};

// Component
export default function GameItem({ content, onRemove }: ComponentProps) {
	return (
		<Root position='apart' spacing={48}>
			<Group w='100%' position='apart' sx={{ flex: 1 }}>
				<Stack spacing={0}>
					<Title order={5} style={{ textTransform: 'capitalize' }}>
						{content.name}
					</Title>
				</Stack>
				<Group spacing={16}>
					<NumberInput 
						defaultValue={0.05}
						label='Frequency'
						precision={2}
						min={0}
						step={0.05}
						max={1} size="sm" 
						placeholder='Frequency'
					/>
					<NumberInput label='Max Attempts' size="sm" placeholder='Max Attempts' defaultValue={1} min={1} />
				</Group>
			</Group>

			<Group  spacing={'lg'}>
				<Group position='right' spacing={'xs'}>
					<ActionIcon variant="subtle" color="red" onClick={onRemove}>
						<Icon icon={"fluent:dismiss-12-filled"} height={20} />
					</ActionIcon>
				</Group>
			</Group>
		</Root>
	);
}

// Styling
const Root = styled(Group)`
	flex-direction: row;

	background-color: #ffffff;
	padding: 8px 16px;
	user-select: none;

	&:not(:last-child) {
		border-bottom: 1px solid #e9ecef;
	}

	& {
		transition-property: background-color;
		transition-duration: 250ms;
		transition-timing-function: ease-in-out;
	}
`;
