import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import {
	Center,
	Input,
	Group,
	Image,
	Stack,
	Text,
	TextInput,
	Card,
	Button,
	Textarea,
} from '@mantine/core';
import useMutationData from 'hooks/useMutationData';
import { useMerchantNavigation } from 'hooks/useMerchantNavigation';
import { showNotification } from '@mantine/notifications';

const inputStyles = {
	label: {
		marginBottom: 12,
	},
	input: {
		background: '#FCFDFE',
		border: '1px solid #F1F3F5',
		borderRadius: '4px',
		"::placeholder": {
			color: '#9FA2B4'
		}
	}
}

// Component
export default function PlatformCollectionCardEdit() {
	const { id } = useParams();
	const form = useFormContext();
	const navigate = useNavigate();
	const { route } = useMerchantNavigation();

	const removeRequest = useMutationData({
		url: () => `/admin/collectible-cards/${id}`,
		method: 'delete',
		queryKeys: {
			invalidate: [
				{ queryKey: ["COLLECTABLE_CARDS"] },
				{ queryKey: ["COLLECTABLE_CARD", id] }
			],
		},
		onSuccess: async () => {
			showNotification({
				autoClose: 3000,
				title: 'Success!',
				message: 'Collectable card successfully deleted!',
				color: 'green',
			});

			navigate(`${route}/collection-cards`)
		}
	})

	return (
		<Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
			<Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
				<Group position="apart">
					<Text size={16} weight={600} lh={1.5}>
						{id ? "Overview" : "New Collectable Card"}
					</Text>
					<Group spacing={8}>
						{id && (
							<Button loading={removeRequest.isLoading} variant="filled" h={28} px={12} py={4} color="red.5" radius={4} onClick={() => removeRequest.mutate(id)}>
								<Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>DELETE</Text>
							</Button>
						)}
						{!id && (
							<Button variant="filled" h={28} px={12} py={4} color="red.5" radius={4} onClick={() => navigate(`${route}/collection-cards`)}>
								<Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>CANCEL</Text>
							</Button>
						)}

						<Button loading={form.formState.isSubmitting} type="submit" variant="filled" h={28} px={12} py={4} color="teal.7" radius={4}>
							<Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>{id ? 'UPDATE' : 'CREATE'}</Text>
						</Button>
					</Group>
				</Group>
			</Card.Section>
			<Card.Section inheritPadding p={24}>
				<Group spacing={24} align='normal'>
					<Stack spacing={24} sx={{ flex: 1 }}>
						<TextInput
							{...form.register('name')}
							label="Name"
							placeholder="Enter card name"
							required
							styles={inputStyles}
						/>

						<Textarea
							{...form.register('description')}
							label='Description'
							placeholder='Enter card description'
							required
							styles={inputStyles}
						/>
					</Stack>
					<Stack spacing={24} h={"100%"} sx={{ flex: 1 }}>
						{!!id && (
							<Controller name='imagURL' control={form.control} render={({ field }) => (
								<Input.Wrapper label={'Preview'} style={{ flex: 1 }}>
									<Image
										styles={{ image: { objectFit: 'contain' } }}
										fit={'contain'}
										height={300}
										src={field.value}
										alt='preview picture'
										withPlaceholder
										radius={'lg'}
									/>
								</Input.Wrapper>
							)} />
						)}

						<Controller name='image' control={form.control} render={({ field, fieldState, formState }) => (
							<Input.Wrapper label='Upload image' style={{ flex: 1, height: '100%' }} styles={{ label: { marginBottom: 12 } }} error={fieldState.error?.message}>
								<Dropzone loading={formState.isSubmitting} maxSize={3 * 1024 ** 2} accept={[MIME_TYPES.png, MIME_TYPES.jpeg]} multiple={false} onDrop={files => field.onChange(files[0])} p={0}>
									<Center style={{ pointerEvents: 'none', padding: Boolean(field.value) ? 0 : 16 }}>
										{Boolean(field.value) && (
											<Image
												fit={'contain'}
												styles={{ image: { objectFit: 'contain' } }}
												radius={'sm'}
												src={URL.createObjectURL(field.value)}
												alt='preview picture'
											/>
										)}

										{!Boolean(field.value) && (
											<Stack spacing={0} align={'center'}>
												<Text size={14} weight={500}>Drag image here or click to select files</Text>
												<Text size={12} color={"gray.6"} maw={380}>
													Attach as many files as you like, supported formats: <Text weight={500} span>jpeg, png</Text>. 
													<br /> Each file should not exceed 10 MB
												</Text>
											</Stack>
										)}
									</Center>
								</Dropzone>
							</Input.Wrapper>
						)} />
					</Stack>
				</Group>
			</Card.Section>
		</Card>
	)
}