import { useMemo } from "react";
import useMerchantGames from "./useMerchantGames";

export default function useMerchantGame(merchant_id: string | number, game_id: string | number) {
  const { games, ...options } = useMerchantGames(merchant_id);

  const game = useMemo(() => {
    return games.find((game) => game.id === Number(game_id));
  }, [games, game_id]);

  return {
    game,
    ...options,
  };
}
