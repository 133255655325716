import { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import EventEdit from "features/EventEdit";
import { FormProvider, useForm } from "react-hook-form";
import { useEvent } from "features/EventEdit/hooks/useEvent";
import { Button, Group, Stack, Text } from "@mantine/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "features/Auth/hooks/useAuth";
import { useEventDelete } from "features/EventEdit/hooks/useEventDelete";
import { useEventCreate } from "features/EventEdit/hooks/useEventCreate";
import { useEventUpdate } from "features/EventEdit/hooks/useEventUpdate";
import ContentLoader from "components/ContentLoader";
import { EventInput, EventWorkDays } from "api/interfaces/events";
import useLocationList from "features/LocationList/hooks/useLocationList";
import { useAdmins } from "features/Admins/useAdmins";
import { EventAnalyticDashboard } from "features/EventEdit/components/EventAnalyticDashboard";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import dayjs from "dayjs";
import { useEventCreateWorkDays } from "../../../features/EventEdit/hooks/useEventCreateWorkDays";
import { useEventDaysOfWeek } from "../../../features/EventEdit/hooks/useEventDaysOfWeek";
import { useEventCards } from "features/EventEdit/store/event-cards.store";
import { api } from "utils/api";
import { useQueryClient } from "react-query";
import QueryKeys from "constants/query-keys";

// Validation Schema
const schema = yup
  .object({
    name: yup
      .string()
      .max(32, `Company name is too long. Maximum length is 32 chars.`)
      .lowercase()
      .trim()
      .required("Required"),
    startDate: yup
      .date()
      .required("Required")
      .typeError("Required")
      .nullable()
      .default(undefined),
    endDate: yup
      .date()
      .min(yup.ref("startDate"), "End date can't be before start date")
      .required("Required")
      .typeError("Required")
      .nullable()
      .default(undefined),
    description: yup.string().required("Required").trim(),
    notes: yup.string().trim().required("Required"),
    adminId: yup.number(),
    locations: yup.array().of(yup.number()),
    timeActivateLimitStart: yup
      .string()
      .when("activationLimit", {
        is: true,
        then: yup
          .string()
          .length(5, "Fill in the date field")
          .required("Required"),
        otherwise: yup.string().nullable().default(undefined),
      })
      .trim(),
    timeActivateLimitEnd: yup
      .string()
      .when("activationLimit", {
        is: true,
        then: yup
          .string()
          .length(5, "Fill in the date field")
          .required("Required"),
        otherwise: yup.string().nullable().default(undefined),
      })
      .trim(),
    timeReceiveLimitStart: yup
      .string()
      .when("receiveLimit", {
        is: true,
        then: yup
          .string()
          .length(5, "Fill in the date field")
          .required("Required"),
        otherwise: yup.string().nullable().default(undefined),
      })
      .trim(),
    timeReceiveLimitEnd: yup
      .string()
      .when("receiveLimit", {
        is: true,
        then: yup
          .string()
          .length(5, "Fill in the date field")
          .required("Required"),
        otherwise: yup.string().nullable().default(undefined),
      })
      .trim(),
  })
  .required();

interface FormState {
  name: EventInput["name"];
  startDate: EventInput["startDate"];
  endDate: EventInput["endDate"];
  description: EventInput["description"];
  notes: EventInput["notes"];
  totalVisits: number;
  payToUser: boolean;

  timeActivateLimitStart?: string;
  timeActivateLimitEnd?: string;
  timeReceiveLimitStart?: string;
  timeReceiveLimitEnd?: string;

  receiveLimit?: boolean;
  activationLimit?: boolean;
  receiveRewardTimeTypeId?: string;
  eventTypeId: string;
  daysOfWeekId: string[];
  contents: number[];
}

// Page
export default function CampaignUpdate() {
  const { user } = useAuth();
  const { id } = useParams();
  const params = useParams();
  const client = useQueryClient();
  const { isLoading: adminsLoading } = useAdmins();
  const { isLoading: locationsLoading } = useLocationList();
  const { event } = useEvent(Number(id));
  const { eventDaysOfWeek } = useEventDaysOfWeek(Number(id));
  const { handleCreate } = useEventCreate();
  const { handleCreateWorkDays } = useEventCreateWorkDays();
  const { handleUpdate } = useEventUpdate(Number(id));
  const { handleDelete } = useEventDelete(event);
  const { route } = useMerchantNavigation();
  const navigate = useNavigate();
  const eventCards = useEventCards(state => state.getFormattedData);
  const resetStore = useEventCards(state => state.resetStore);
  const form = useForm<FormState>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      description: "",
      notes: "",
      startDate: "",
      endDate: "",
      totalVisits: 0,
      payToUser: true,
      contents: [],
    },
    mode: "onChange",
  });
  const saveWorkDays = useCallback(
    async (input: FormState, eventId: number) => {
      const prevEventDaysOfWeek = eventDaysOfWeek?.items.map((el) => String(el.dayOfWeekId));
      if (JSON.stringify(input.daysOfWeekId) !== JSON.stringify(prevEventDaysOfWeek) && input.eventTypeId === "2") {
        const dataWorkDays = {} as EventWorkDays;
        Object.assign(dataWorkDays, {
          id: eventId,
          daysOfWeekId: input.daysOfWeekId.map((el) => Number(el))
        })
        await handleCreateWorkDays(dataWorkDays)
      }
    }, [event, handleCreateWorkDays]
  )

  const onSubmit = useCallback(
    async (input: FormState) => {
      const data = {} as EventInput;
      try {
        const activationTime = {
          startTime: `${input.timeActivateLimitStart}:00`,
          endTime: `${input.timeActivateLimitEnd}:00`,
        };
        const receiveTime = {
          startTime: `${input.timeReceiveLimitStart}:00`,
          endTime: `${input.timeReceiveLimitEnd}:00`,
        };

        Object.assign(data, {
          name: input.name,
          startDate: input.startDate,
          endDate: input.endDate,
          description: input.description,
          notes: input.notes,

          totalVisits: input.totalVisits,
          payToUser: input.payToUser,

          activationLimit: input.activationLimit,
          receiveLimit: input.receiveLimit,

          receiveRewardTimeTypeId: input.receiveRewardTimeTypeId ? Number(input.receiveRewardTimeTypeId) : 1,
          eventTypeId: input.eventTypeId ? Number(input.eventTypeId) : 1,
          contents: input.eventTypeId === "2" ? input.contents : [],

          activationTime,
          receiveTime,
        });

        data.startDate = dayjs(
          dayjs(input.startDate).startOf("day")
        ).toString();
        data.endDate = dayjs(dayjs(input.endDate).startOf("day")).toString();

        data.activationTime = input.activationLimit
          ? activationTime
          : undefined;

        data.receiveTime = input.receiveLimit ? receiveTime : undefined;

        console.log("input", input);
        console.log("data", data);

        if (!event) {
          const res = await handleCreate(data);
          if (res) {
            await saveWorkDays(input, res);
            const payload = eventCards() ? eventCards() : { cards: [] };
            await api.post(`/admin/merchants/${params.merchantId}/events/${res}/collectible-cards?limit=1000&skip=0`, payload);
            resetStore();
          }
          navigate(`${route}/campaigns/${res}`);
          console.log("CREATED EVENT");
        }

        if (!!event) {
          const a = { ...event, ...data };
          console.log("a", a);
          await saveWorkDays(input, Number(id)).then(() => {
            handleUpdate(a);
            form.reset();
          });
          const payload = eventCards() ? eventCards() : { cards: [] };
          await api.post(`/admin/merchants/${params.merchantId}/events/${params.id}/collectible-cards?limit=1000&skip=0`, payload);
          resetStore();
          console.log("UPDATED EVENT");
        }
      } catch (e) {
        console.log(e);
      }
    },
    [event, form, handleCreate, handleUpdate]
  );

  const convertTime = (time: string | undefined): string | undefined => {
    if (!time) return;
    let [hours, minutes] = time.split(":");
    let filteredTime = `${hours}:${minutes}`;

    return filteredTime;
  };

  // Update Form Data
  useEffect(() => {
    if (!event) return;

    const obj = {
      timeActivateLimitStart: convertTime(event?.activationTime?.startTime),
      timeActivateLimitEnd: convertTime(event?.activationTime?.endTime),
      timeReceiveLimitStart: convertTime(event?.receiveTime?.startTime),
      timeReceiveLimitEnd: convertTime(event?.receiveTime?.endTime),
    };

    console.log("event", event);
    console.log("obj", obj);

    form.reset({
      name: event.name,
      notes: event.notes,
      description: event.description,
      startDate: event.startDate,
      endDate: event.endDate,
      totalVisits: event.totalVisits,
      payToUser: event.payToUser,
      receiveRewardTimeTypeId: event.receiveRewardTimeTypeId ? String(event.receiveRewardTimeTypeId) : "1",
      eventTypeId: event.eventTypeId ? String(event.eventTypeId) : "1",

      activationLimit: event?.activationLimit,
      receiveLimit: event?.receiveLimit,
      daysOfWeekId: eventDaysOfWeek ? eventDaysOfWeek.items.map((el) => String(el.dayOfWeekId)) : [],
      contents: event.eventContents?.map((content) => content.id),

      ...obj,
    });
  }, [event, form, user, eventDaysOfWeek]);

  if ((id && !event) || adminsLoading || locationsLoading) {
    return <ContentLoader />;
  }

  const handleBackLink = async () => {
    resetStore();
    await client.invalidateQueries([QueryKeys.CARDS_FOR_EVENT]);
  } 

  return (
    <FormProvider {...form}>
      <Root onSubmit={form.handleSubmit(onSubmit)}>
        <Stack spacing={0}>
          <Group position="apart">
            <Group position="left">
              <BackLink to={`${route}/campaigns`} onClick={handleBackLink}>
                <span className={"icon"}>
                  <Icon icon={"fluent:arrow-left-12-filled"} />
                </span>
                <Text size="xl" weight={"bold"}>
                  Back to Campaigns
                </Text>
              </BackLink>
            </Group>
            <Group position="right">
              {!!event && (
                <Button
                  leftIcon={
                    <Icon icon={"fluent:delete-24-filled"} height={18} />
                  }
                  onClick={handleDelete}
                  radius={"sm"}
                  color={"red"}
                  variant={"filled"}
                >
                  Delete
                </Button>
              )}
              {!event && (
                <Button
                  leftIcon={
                    <Icon icon={"fluent:delete-24-filled"} height={18} />
                  }
                  component={Link}
                  to={`${route}/campaigns`}
                  radius={"sm"}
                  color={"red"}
                  variant={"filled"}
                >
                  Cancel
                </Button>
              )}

              <Button
                type="submit"
                leftIcon={<Icon icon={"fluent:save-24-filled"} height={18} />}
                radius={"sm"}
                color={"teal"}
                variant={"filled"}
                loading={form.formState.isSubmitting}
              >
                {event ? "Update" : "Create"}
              </Button>
            </Group>
          </Group>

          <EventEdit />

          {id && <EventAnalyticDashboard />}
        </Stack>
      </Root>
    </FormProvider>
  );
}

// Styling
const Root = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #2c3e50;

  & {
    transition-property: color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    color: #0083ce;
  }

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-right: 8px;
  }

  & .title {
    font-size: 20px;
    font-weight: bold;
  }
`;
