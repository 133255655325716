import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { Group, Avatar, ActionIcon, Text, Stack } from "@mantine/core";
import { TCollectableCard } from "features/CollectionCardsList/types";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSharingGameStore } from "store/game-sharing.store";

type SharedItemProps = {
    card: TCollectableCard;
}

export default function ListItem({ card }: SharedItemProps) {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);

    const removeCards = useSharingGameStore(state => state.removeCollectibleCards);

    const onRemove = (merchantId: number) => {
        setLoading(true);
        const timeout = setTimeout(() => {
            removeCards(Number(id), [merchantId]);
            setLoading(false);
            clearTimeout(timeout);
        }, 1000);
    }


    return (
        <Root position='apart' px={16} py={8}>
            <Group position='left' align={'center'} spacing={8}>
                {card.imagURL
                    ? <Avatar size="sm" radius={'xl'} src={card.imagURL} alt="it's me" />
                    : <Avatar size="sm" radius={'xl'} color='blue'>{card.name[0].toUpperCase()}</Avatar>
                }
                <Stack spacing={2}>
                    <Text size={'sm'} weight={500}>{card.name}</Text>
                    <Text size={'xs'} color={'gray'}>{card.description}</Text>
                </Stack>
            </Group>
            <Group position='right'>
                <ActionIcon
                    variant={'subtle'}
                    onClick={() => onRemove(card.id)}
                    loading={loading}
                    sx={(theme) => ({
                        background: '#FCFDFE',
                        border: '1px solid #F1F3F5',
                        borderRadius: '6px',
                        color: theme.colors.red[5],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.red[5],
                        }
                    })}
                >
                    <Icon icon={'fluent:delete-16-regular'} height={14} />
                </ActionIcon>
            </Group>
        </Root>
    )
}

const Root = styled(Group)`
    &:not(:last-child) {
        border-bottom: 1px solid #f8f9fa;
    }
`