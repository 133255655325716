import { Constants } from "api/interfaces/constants";
import { api } from "index";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { ConstantIds } from "../api/interfaces/settings";

export const useServerConstants = () => {
  const { data, refetch } = useQuery(
    ["ADMIN_CONSTANTS"],
    () => api.constants.readAll({}),
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    }
  );

  const constants: Constants = useMemo(
    () => ({
      Country: [],
      ImageContentType: [],
      ContentType: [],
      Role: [],
      Constant: [],
      TermsType: [],
      TransferType: [],
      ExpirationType: [],
      AllowedCity: [],
      ...data,
    }),
    [data]
  );

  const FaqValue: string = useMemo(() => {
    return (
      constants.Constant.find((item) => item.id === ConstantIds.FAQ)
        ?.textValue || ""
    );
  }, [constants]);

  const TermsOfUseValue: string = useMemo(() => {
    return (
      constants.Constant.find((item) => item.id === ConstantIds.TERMS_OF_USE)
        ?.textValue || ""
    );
  }, [constants]);

  return { ...constants, refetch, TermsOfUseValue, FaqValue };
};
