import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import EventList from "features/EventList";
import useEventList from "features/EventList/hooks/useEventList";
import ContentLoader from "components/ContentLoader";
import { Button, Group, Stack, Text } from "@mantine/core";
import {useMerchantNavigation} from "../../../hooks/useMerchantNavigation";
import { useEventCards } from "features/EventEdit/store/event-cards.store";
import { useQueryClient } from "react-query";
import QueryKeys from "constants/query-keys";

// Page
export default function Campaigns() {
  const { events, isLoading } = useEventList();
  const { route } = useMerchantNavigation()
  const resetStore = useEventCards(state => state.resetStore);
  const queryClient = useQueryClient();

  const handleCreate = async () => {
    await queryClient.invalidateQueries([QueryKeys.CARDS_FOR_EVENT]);
    resetStore();
  }

  return (
    <Stack p={"lg"} spacing={0}>
      <Group position="apart">
        <Group position="left">
          <Text size="xl" weight="bold">
            Campaigns
          </Text>
        </Group>
        <Group position="right">
          <Button
            component={Link}
            to={`${route}/campaigns/create`}
            leftIcon={<Icon icon={"fluent:add-12-filled"} height={18} />}
            radius={"sm"}
            color={"blue"}
            variant={"filled"}
            onClick={handleCreate}
          >
            Create
          </Button>
        </Group>
      </Group>

      {!isLoading ? <EventList events={events} /> : <ContentLoader />}
    </Stack>
  );
}
