import { Icon } from "@iconify/react";
import { Button, Card, Group, Modal, Text, Tooltip } from "@mantine/core";
import { nanoid } from "@reduxjs/toolkit";
import { RandomRewardList } from "features/RandomReward/RandomRewardList";
import { CreateRewardModal } from "features/RandomReward/components/CreateRewardModal";
import { TRandomReward } from "features/RandomReward/types";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function RandomRewardForm() {
    const [rewards, setRewards] = useState<TRandomReward[]>([]);
    const { id: campaignId, merchantId } = useParams();
    const [createModalStatus, setCreateModalStatus] = useState<boolean>(false);
    
    useEffect(() => {
        const storedRewards = JSON.parse(localStorage.getItem("rewards")!) || [];
        console.log(storedRewards);
        setRewards(storedRewards.filter((item: any) => item.campaignId == campaignId));
    }, []);
        
    const handleCreate = (reward: any) => {
        reward.campaignId = campaignId;
        reward.id = nanoid();
        const storedRewards = JSON.parse(localStorage.getItem("rewards")!) || [];
        localStorage.setItem("rewards", JSON.stringify([...storedRewards, reward]));
        setRewards([...rewards, reward ]);
    }

    const handleDelete = (id: string) => {
        const storedRewards = JSON.parse(localStorage.getItem("rewards")!) || [];
        localStorage.setItem("rewards", JSON.stringify(storedRewards.filter((item: any) => item.id !== id)));
        setRewards(rewards.filter(item => item.id !== id))
    }
        
    if (!merchantId) return null;

	return (
        <>
            <Card radius={8} withBorder style={{ overflow: "visible" }}>
                <Card.Section inheritPadding withBorder py={16}>
                    <Group position="apart">
                        <Group position="left">
                            <Text size="lg" weight={700}>
                                Random Reward
                            </Text>

                            <Tooltip multiline width={220} withArrow label="When a user is added, they can receive either a random reward or a manually selected reward.">
                                <Icon icon="material-symbols:info-outline" color="#f55200" />
                            </Tooltip>
                        </Group>
                        <Group>
                            <Button
                                leftIcon={<Icon icon={"fluent:add-12-filled"} height={18} />}
                                radius={"sm"}
                                variant={"filled"}
                                sx={{ maxWidth: 300 }}
                                onClick={() => setCreateModalStatus(true)}
                            >
                                Add Reward
                            </Button>
                        </Group>
                    </Group>
                </Card.Section>
                <Card.Section inheritPadding pb={16}>
                    <RandomRewardList handleDelete={handleDelete} rewards={rewards} />
                </Card.Section>
            </Card>
            <Modal
                onClose={() => setCreateModalStatus(false)}
                opened={createModalStatus}
                withCloseButton={false}
                overlayProps={{opacity: 0.5}}
                transitionProps={{ transition: "slide-down", duration: 400 }}
                closeOnClickOutside={false}
                padding={30}
                centered
                size={"md"}
            >
                <CreateRewardModal
                    handleCreate={handleCreate}
                    close={() => setCreateModalStatus(false)}
                />
            </Modal>
        </>
	);
}
