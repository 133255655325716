import { Button, Card, Group, Stack, Text } from "@mantine/core";
import ContentLoader from "components/ContentLoader";
import { CreateForm } from "features/PlatformGames/components/CreateForm";
import ListItem from "features/PlatformGames/components/ListItem";
import { TGame } from "features/PlatformGames/types";
import Modal from "features/Modal";
import { ModalTrigger } from "features/Modal/constants";
import { useModalStore } from "features/Modal/store/modal.store";
import usePaginatedData from "hooks/usePaginatedData";
import { useSharingGameStore } from "store/game-sharing.store";

// Games Page
export default function GamesPage() {
    const open_modal = useModalStore((state) => state.setOpen);

    const syncSharingGames = useSharingGameStore((state) => state.sync);

    const { items, isLoading } = usePaginatedData<TGame>({
        path: "/admin/games",
        defaultLimit: 1000,
        queryKey: ["GAME_LIST"],
        onSuccess(data) {
            syncSharingGames(data.items?.map((item: any) => item.id) ?? []);
        },
    });

    const onOpenCreate = () => {
        open_modal({ trigger: ModalTrigger.ADD_GAME, title: "New Game" });
    };

    return (
        <Stack p={24} spacing={24}>
            <Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
                <Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
                    <Group position="apart">
                        <Text size={16} weight={600} lh={1.5}>
                            Games
                        </Text>
                        <Group>
                            <Button type="submit" variant="filled" h={28} px={12} py={4} color="dark.8" radius={4} onClick={onOpenCreate}>
                                <Text size={"14px"} weight={500} tt={"uppercase"} lh={1.5}>
                                    ADD
                                </Text>
                            </Button>
                        </Group>
                    </Group>
                </Card.Section>
                <Card.Section inheritPadding p={0} pb={2}>
                    <Stack spacing={0}>
                        {isLoading && <ContentLoader />}

                        {items.length > 0 &&
                            items.map((item) => <ListItem key={item.id} game={item} />)}

                        {!isLoading && items.length === 0 && (
                            <Group p={24} align="center" position="center">
                                <Text size={"14px"} weight={400} color="gray.6" lh={1.5}>
                                    Merchant list is empty
                                </Text>
                            </Group>
                        )}
                    </Stack>
                </Card.Section>
            </Card>

            <Modal triggers={[ModalTrigger.ADD_GAME]}>
                <CreateForm />
            </Modal>
        </Stack>
    );
}
