import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type TDay = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export type TWorkingDay = {
    day: TDay;
    start: string;
    end: string;
}

export type TEventDays = {
    event_id: number;
    working_days: TWorkingDay[];
}

interface IEventWorkingDatesStore {
    workingDates: TEventDays[];

    addEvent: (event_id: TEventDays['event_id']) => void;
    removeEvent: (data: TEventDays['event_id']) => void;

    addWorkingDay: (event_id: TEventDays['event_id'], day: TWorkingDay) => void;
    removeWorkingDay: (event_id: TEventDays['event_id'], day: TWorkingDay['day']) => void;

    setStartTime: (event_id: TEventDays['event_id'], day: TWorkingDay['day'], time: TWorkingDay['start']) => void;
    setEndTime: (event_id: TEventDays['event_id'], day: TWorkingDay['day'], time: TWorkingDay['end']) => void;
}

const InitialState: Pick<IEventWorkingDatesStore, 'workingDates'> = {
    workingDates: localStorage.getItem('EVENT_WORKING_DATES') ? JSON.parse(localStorage.getItem('EVENT_WORKING_DATES')!) : [],
};

export const useEventWorkingDates = create<IEventWorkingDatesStore>()(
    devtools(
        (set, get) => ({
            ...InitialState,

            addEvent: (event_id) => {
                const working_days = get().workingDates;

                if (working_days.some(({ event_id: id }) => id === event_id)) return;

                const new_working_days = [...working_days, { event_id: event_id, working_days: [] }] as TEventDays[];
                localStorage.setItem('EVENT_WORKING_DATES', JSON.stringify(new_working_days));
                set({ workingDates: new_working_days });
            },

            removeEvent: (event_id) => {
                const working_days = get().workingDates;

                if (!working_days.some(({ event_id: id }) => id === event_id)) return;

                const new_working_days = working_days.filter(({ event_id: id }) => id !== event_id);
                localStorage.setItem('EVENT_WORKING_DATES', JSON.stringify(new_working_days));
                set({ workingDates: new_working_days });
            },

            addWorkingDay: (event_id, data) => {
                const working_days = get().workingDates;

                if (working_days.some((item) => item.event_id !== event_id || item.working_days.some(item => item.day === data.day))) return;

                const updated_working_days = working_days.map((item) => {
                    if (item.event_id !== event_id || item.working_days.some(item => item.day === data.day)) return item;
                    return { ...item, working_days: [...item.working_days, data] };
                });

                localStorage.setItem('EVENT_WORKING_DATES', JSON.stringify(updated_working_days));
                set({ workingDates: updated_working_days });
            },

            removeWorkingDay: (event_id, day) => {
                const working_days = get().workingDates;

                if (working_days.some((item) => item.event_id !== event_id || !item.working_days.some(item => item.day === day))) return;

                const updated_working_days = working_days.map((item) => {
                    if (item.event_id !== event_id || !item.working_days.some(item => item.day === day)) return item;
                    return { ...item, working_days: item.working_days.filter(item => item.day !== day) };
                });

                localStorage.setItem('EVENT_WORKING_DATES', JSON.stringify(updated_working_days));
                set({ workingDates: updated_working_days });
            },

            setStartTime: (event_id, day, time) => {
                const working_days = get().workingDates;

                if (working_days.some((item) => item.event_id !== event_id || !item.working_days.some(item => item.day === day))) return;

                const updated_working_days = working_days.map((item) => {
                    if (item.event_id !== event_id || !item.working_days.some(item => item.day === day)) return item;
                    return { ...item, working_days: item.working_days.map(item => item.day === day ? { ...item, start: time } : item) };
                });

                localStorage.setItem('EVENT_WORKING_DATES', JSON.stringify(updated_working_days));
                set({ workingDates: updated_working_days });
            },

            setEndTime: (event_id, day, time) => {
                const working_days = get().workingDates;

                if (working_days.some((item) => item.event_id !== event_id || !item.working_days.some(item => item.day === day))) return;

                const updated_working_days = working_days.map((item) => {
                    if (item.event_id !== event_id || !item.working_days.some(item => item.day === day)) return item;
                    return { ...item, working_days: item.working_days.map(item => item.day === day ? { ...item, end: time } : item) };
                });

                localStorage.setItem('EVENT_WORKING_DATES', JSON.stringify(updated_working_days));
                set({ workingDates: updated_working_days });
            },
        }),
        { name: 'Event Working Dates' },
    ),
);
