import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import DashboardLayout from "features/Layouts/DashboardLayout";

import Campaigns from "pages/merchant/campaigns/Campaigns";
import CampaignUpdate from "pages/merchant/campaigns/CampaignUpdate";
import LocationUpdate from "pages/merchant/locations/LocationUpdate";
import Locations from "pages/merchant/locations/Locations";
import TextContents from "pages/merchant/text-contents/TextContents";
import TextContentUpdate from "pages/merchant/text-contents/TextContentUpdate";
import ImageContents from "pages/merchant/image-contents/ImageContents";
import ImageContentUpdate from "pages/merchant/image-contents/ImageContentUpdate";
import Contents from "pages/merchant/contents/Contents";
import ContentUpdate from "pages/merchant/contents/ContentUpdate";
import ProfileUpdate from "pages/profile/ProfileUpdate";
import SubscribePage from "pages/merchant/subscribe/SubscribePage";
import { useAuth } from "../Auth/hooks/useAuth";
import LocationEvents from "../../pages/merchant/locationEvents/LocationEvents";
import LocationEventUpdate from "../../pages/merchant/locationEvents/LocationEventUpdate";
import { FaqSettings } from "../Settings/FaqSettings";
import { TermsOfUseSettings } from "../Settings/TermsOfUseSettings";
import AppAccess from "pages/platform/appAccess/AppAccess";
import { FeedbackSettings } from "features/Settings/FeedbackSettings";
import { MerchantAdmins } from "pages/merchant/admins/MerchantAdmins";
import { PlatformAdmins } from "pages/platform/platformAdmins/PlatformAdmins";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";
import { VerificationPage } from "../../pages/merchant/verifications";
import { CompanyProfile } from "../../pages/merchant/profile";
import MerchantAnalyticsPage from "../../pages/merchant/analytics";
import PlatformAnalyticsPage from "../../pages/platform/analytics";
import MerchantAgreementsPage from "../../pages/merchant/agreement/MerchantAgreementPage";
import { UsersPage } from "pages/platform/users";
import { UpdateUserPage } from "pages/platform/users/UpdateUser";
import { TransferHistoryPage } from "pages/merchant/transferHistory";
import { useMemo } from "react";
import { StartupPage } from "pages/platform/startup";
import { PaymentSettings } from "features/Settings/PaymentSettings";
import ReferralProgram from "../../pages/platform/referralProgram/ReferralProgram";
import Advertisements from "pages/platform/advertisements/Advertisements";
import AdvertisementsUpdate from "pages/platform/advertisements/AdvertisementsUpdate";
import CollectionCards from 'pages/merchant/collection-cards';
import GamesPage from 'pages/platform/games/index';
import MerchantGames from "pages/merchant/games/index";
import MerchantGameDetails from "pages/merchant/games/[id]";
import PlatformGameDetails from "pages/platform/games/[id]";
import CollectableCardEditPage from "pages/merchant/collection-cards/edit";
import { FeeSettings } from "features/Settings/FeeSettings";
import MerchantTargetGroup from "pages/merchant/content-targets";
import PlatformTargetGroup from "pages/platform/merchant-targets";
import PlatformCollectableCardEditPage from "pages/platform/collection-cards/edit";
import PlatformCollectableCards from "pages/platform/collection-cards";

export default function SecureRoutes() {
  const {
    isSuperMerchant,
    isPlatformAdmin,
    isMerchantSubscribed,
    isSuperAdmin,
  } = useAuth();

  const merchantSelector = useMerchantSelectorStore();
  const selected = merchantSelector.useStore((state) => state.selected);
  const isMerchantSelected = useMemo(() => {
    if (selected !== "undefined" && !!selected) return true;

    return false;
  }, [selected]);

  const notFoundLink = useMemo(() => {
    if (isPlatformAdmin && !isMerchantSelected) {
      return "/startup";
    }

    return `/merchant/${selected}/profile`;
  }, [isPlatformAdmin, isMerchantSelected]);

  return (
    <Routes>
      <Route path="/" element={<DashboardLayout />}>
        {isPlatformAdmin && !isMerchantSelected && (
          <Route path="startup" element={<StartupPage />} />
        )}

        {!!(isMerchantSubscribed || isPlatformAdmin) ? (
          <Route index element={<Navigate replace to="/platform/admins" />} />
        ) : (
          <Route
            index
            element={<Navigate replace to="/merchant/subscribe" />}
          />
        )}

        {isMerchantSelected && (
          <Route path="merchant/:merchantId" element={<Outlet />}>
            <Route
              index
              element={
                <Navigate replace to={`/merchant/${selected}/profile`} />
              }
            />
            <Route path="profile" element={<CompanyProfile />} />

            {(isMerchantSubscribed || isPlatformAdmin) && (
              <Route path="campaigns" element={<Outlet />}>
                <Route index element={<Campaigns />} />
                <Route path="create" element={<CampaignUpdate />} />
                <Route path=":id" element={<CampaignUpdate />} />
              </Route>
            )}

            {(isMerchantSubscribed || isPlatformAdmin) && (
              <Route path="locations" element={<Outlet />}>
                <Route index element={<Locations />} />
                <Route path="create" element={<LocationUpdate />} />
                <Route path=":id" element={<LocationUpdate />} />
              </Route>
            )}

            {(isMerchantSubscribed || isPlatformAdmin) && (
              <Route path="contents" element={<Outlet />}>
                <Route index element={<Contents />} />
                <Route path="create" element={<ContentUpdate />} />
                <Route path=":id" element={<ContentUpdate />} />
              </Route>
            )}

            {(isMerchantSubscribed || isPlatformAdmin) && (
              <Route path="text-contents" element={<Outlet />}>
                <Route index element={<TextContents />} />
                <Route path="create" element={<TextContentUpdate />} />
                <Route path=":id" element={<TextContentUpdate />} />
              </Route>
            )}

            {(isMerchantSubscribed || isPlatformAdmin) && (
              <Route path="image-contents" element={<Outlet />}>
                <Route index element={<ImageContents />} />
                <Route path="create" element={<ImageContentUpdate />} />
                <Route path=":id" element={<ImageContentUpdate />} />
              </Route>
            )}

            {(isMerchantSubscribed || isPlatformAdmin) && (
              <Route path="collectable-cards" element={<Outlet />}>
                <Route index element={<CollectionCards />} />
                <Route path="create" element={<CollectableCardEditPage />} />
                <Route path=":id" element={<CollectableCardEditPage />} />
              </Route>
            )}

            {(isMerchantSubscribed || isPlatformAdmin) && (
              <Route path="location-events" element={<Outlet />}>
                <Route index element={<LocationEvents />} />
                <Route path="create" element={<LocationEventUpdate />} />
                <Route path=":id" element={<LocationEventUpdate />} />
              </Route>
            )}

            {((isSuperMerchant && isMerchantSubscribed) || isPlatformAdmin) && (
              <Route path={"verification"} element={<VerificationPage />} />
            )}
            
            {(isSuperMerchant || isPlatformAdmin) && (
              <Route path="subscribe" element={<Outlet />}>
                <Route index element={<SubscribePage />} />
              </Route>
            )}

            {(isSuperMerchant || isPlatformAdmin) && (
              <Route path="transfer-history" element={<Outlet />}>
                <Route index element={<TransferHistoryPage />} />
              </Route>
            )}

            <Route path="analytics" element={<Outlet />}>
              <Route index element={<MerchantAnalyticsPage />} />
            </Route>

            <Route path="user-agreement" element={<Outlet />}>
              <Route index element={<MerchantAgreementsPage />} />
            </Route>

            <Route path="games" element={<Outlet />}>
              <Route index element={<MerchantGames />} />
              <Route path=":id" element={<MerchantGameDetails />} />
            </Route>

            <Route path="content-targets" element={<Outlet />}>
              <Route index element={<MerchantTargetGroup />} />
            </Route>

            {(isPlatformAdmin || (isSuperMerchant && isMerchantSubscribed)) && (
              <Route path="admins" element={<MerchantAdmins />} />
            )}
          </Route>
        )}

        {isPlatformAdmin && (
          <Route path={"platform"} element={<Outlet />}>
            {isSuperAdmin && (
              <Route path="settings" element={<Outlet />}>
                <Route
                  index
                  element={<Navigate replace to="/platform/settings/faq" />}
                />
                <Route path="faq" element={<FaqSettings />} />
                <Route path="terms-of-use" element={<TermsOfUseSettings />} />
                <Route path="feedback" element={<FeedbackSettings />} />
                <Route path="payment" element={<PaymentSettings />} />
                <Route path="fee" element={<FeeSettings />} />
              </Route>
            )}
            {!isSuperAdmin && (
              <Route path="/platform/advertisements" element={<Outlet />}>
                <Route index element={<Advertisements />} />
                <Route path="create" element={<AdvertisementsUpdate />} />
                <Route path=":id" element={<AdvertisementsUpdate />} />
              </Route>
            )}

            <Route path="collectable-cards" element={<Outlet />}>
              <Route index element={<PlatformCollectableCards />} />
              <Route path="create" element={<PlatformCollectableCardEditPage />} />
              <Route path=":id" element={<PlatformCollectableCardEditPage />} />
            </Route>

            <Route path="admins" element={<Outlet />}>
              <Route index element={<PlatformAdmins />} />
            </Route>

            <Route path="app-access" element={<Outlet />}>
              <Route index element={<AppAccess />} />
            </Route>

            <Route path="analytics" element={<Outlet />}>
              <Route index element={<PlatformAnalyticsPage />} />
            </Route>

            <Route path="merchant-targets" element={<Outlet />}>
              <Route index element={<PlatformTargetGroup />} />
            </Route>

            <Route path="referral-program" element={<Outlet />}>
              <Route index element={<ReferralProgram />} />
            </Route>

            <Route path="users" element={<Outlet />}>
              <Route index element={<UsersPage />} />
              <Route path=":id" element={<UpdateUserPage />} />
            </Route>

            <Route path="games" element={<Outlet />}>
              <Route index element={<GamesPage />} />
              <Route path=":id" element={<PlatformGameDetails />} />
            </Route>
          </Route>
        )}

        <Route path="account" element={<Outlet />}>
          <Route index element={<ProfileUpdate />} />
        </Route>
      </Route>

      <Route path={"*"} element={<Navigate replace to={notFoundLink} />} />
    </Routes>
  );
}
