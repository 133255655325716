import { Button, Card, Group, Stack, Text } from '@mantine/core';
import { useMerchantNavigation } from '../../../hooks/useMerchantNavigation';
// import usePaginatedData from 'hooks/usePaginatedData';
import QueryKeys from 'constants/query-keys';
import { useParams } from 'react-router-dom';
import Modal from 'features/Modal';
import { ModalTrigger } from 'features/Modal/constants';
import AddModalWithSettings from '../components/AddModalWithSettings';
import { useModalStore } from 'features/Modal/store/modal.store';
import { useEventCards } from 'features/EventEdit/store/event-cards.store';
import CollectibleCard from 'features/EventEdit/components/CollectibleCard';
import { useEffect } from 'react';
import ContentLoader from 'components/ContentLoader';
import { useQuery } from 'react-query'
import { api } from 'utils/api';

// Page
export default function CollectableCards() {
    const { id: merchantId } = useMerchantNavigation();
    const { id: contentId } = useParams();

    const cardsList = useEventCards(state => state.cards);
    const addCard = useEventCards(state => state.addCard);
    const openModal = useModalStore(state => state.setOpen);
    const resetStore = useEventCards(state => state.resetStore);

    // const { items: contentCards, isLoading } = usePaginatedData<any>({
    //     path: `/admin/merchants/${merchantId}/contents/${contentId}/collectible-cards`,
    //     defaultLimit: 1000,
    //     queryKey: [QueryKeys.CARDS_FOR_CONTENT],
    // });

    const { data: contentCards, isLoading } = useQuery({
        queryFn: () => contentId ? api.get(`/admin/merchants/${merchantId}/contents/${contentId}/collectible-cards`) : undefined,
        queryKey: [QueryKeys.CARDS_FOR_CONTENT],
    });

    useEffect(() => {
        resetStore();
        if (isLoading) return;
        contentCards?.data?.data?.items?.map((card: any) => {
            addCard({
                id: card.collectibleCardId,
                name: card.CollectibleCard.name,
                frequencyOccurrence: card.frequencyOccurrence,
                maxAttempts: card.maxAttempts,
                settingsId: card.id,
            });
        });
    }, [contentCards, isLoading, contentId]);

    return (
        <Stack p={24} spacing={24}>
            <Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
                <Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
                    <Group position="apart">
                        <Text size={16} weight={600} lh={1.5}>
                            Collectable Cards
                        </Text>
                        <Group>
                            <Button variant="filled" h={28} px={12} py={4} color="dark.8" radius={4} onClick={() => openModal({
                                title: 'Add Collectable Card',
                                trigger: ModalTrigger.SHARE_COLLECTABLE_CARD_TO_CONTENT,
                            })}>
                                <Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>ADD</Text>
                            </Button>
                        </Group>
                    </Group>
                </Card.Section>
                <Card.Section inheritPadding p={0} pb={2}>
                    <Stack spacing={0}>
                        {/* {isLoading && <ContentLoader />} */}

                        {/* {items.length > 0 && items.map((item) => {
                            return <ListItem card={item} key={item.id} />
                        })} */}

                        {/* {!isLoading ? addedCards?.map((card: TStorageCollectibleCard, index) => <CollectibleCard
                            key={index}
                            id={card.id}
                            name={card.name}
                            frequencyOccurrence={card.frequencyOccurrence}
                            maxAttempts={card.maxAttempts}
                            settingsId={card.settingsId}
                            type={"contents"}
                        />) :
                            <Group p={24} align="center" position="center">
                                <Text size={"14px"} weight={400} color="gray.6" lh={1.5}>
                                    No collectable cards found
                                </Text>
                            </Group>
                        } */}

                        {isLoading
                            ? <ContentLoader />
                            : cardsList?.map((card: TStorageCollectibleCard, index: number) => <CollectibleCard
                                key={index}
                                id={card.id}
                                name={card.name}
                                frequencyOccurrence={card.frequencyOccurrence}
                                maxAttempts={card.maxAttempts}
                                settingsId={card.settingsId}
                                type={"contents"}
                            />)
                        }


                        {/* User merchant dont add collection cards in this content */}
                        {/* {!isLoading && items.length === 0 && (
                            <Group p={24} align="center" position="center">
                                <Text size={"14px"} weight={400} color="gray.6" lh={1.5}>
                                    No collectable cards found
                                </Text>
                            </Group>
                        )} */}
                    </Stack>
                </Card.Section>
            </Card>

            <Modal triggers={[ModalTrigger.SHARE_COLLECTABLE_CARD_TO_CONTENT]}>
                <AddModalWithSettings />
            </Modal>
        </Stack>
    );
}
