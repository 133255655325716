import styled from "@emotion/styled";
import { DateTimePicker, TimeInput } from "@mantine/dates";
import { Group, NumberInput, Paper, Select, Stack, Switch, Text, Textarea, TextInput } from "@mantine/core";
import { css } from "@emotion/react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useAdminConstants } from "../../../hooks/useAdminConstants";
import dayjs from "dayjs";
import { useEventTypes } from "../hooks/useEventType";
import CertainDays from "./CertainDays";
import { useQueryClient } from "react-query";
import QueryKeys from "constants/query-keys";
import { useEventCards } from "features/EventEdit/store/event-cards.store";

// Component
export default function EventMainForm() {
	const { control } = useFormContext();
	const { VisitPrice } = useAdminConstants();
	const { eventTypes } = useEventTypes();

	const queryClient = useQueryClient();
	const resetStore = useEventCards(state => state.resetStore);

	const currentDate = new Date();
	const minEndDate = dayjs().add(1, "day").toDate();
	const limitSwitchStatus = useWatch({
		control,
		name: "activationLimit",
	});
	const receiveSwitchStatus = useWatch({
		control,
		name: "receiveLimit",
	});

	const handleChange = async (field: any, value: any) => {
		field.onChange(value);
		await queryClient.invalidateQueries([QueryKeys.CARDS_FOR_EVENT]);
		resetStore();
	};

	return (
		<Root radius="md" withBorder p="lg">
			<Stack spacing={"lg"}>
				<Group grow spacing={"lg"} align={"flex-start"}>
					<Stack spacing="lg">
						{/* Name */}
						<Stack>
							<Controller
								name="name"
								control={control}
								render={({ field, fieldState }) => (
									<TextField
										label="Name"
										placeholder="Enter campaign name"
										variant="filled"
										required
										error={fieldState.error?.message}
										classNames={{ input: "input-field" }}
										{...field}
									/>
								)}
							/>
						</Stack>

						<Stack>
							<Controller
								name="totalVisits"
								control={control}
								render={({ field, fieldState }) => (
									<div>
										<NumberInput
											label="Total visits"
											placeholder="Enter total visits"
											variant="filled"
											hideControls
											required
											classNames={{ input: "input-field" }}
											error={fieldState.error?.message}
											{...field}
										/>

										{!!(!!field.value && VisitPrice) && (
											<Text
												style={{
													fontSize: 14,
													marginTop: 4,
													color: "#00000070",
												}}
												component={"p"}
											>
												Estimated fee: <b>{field.value * VisitPrice}$</b>
											</Text>
										)}
									</div>
								)}
							/>
						</Stack>
					</Stack>

					<Stack spacing="lg">
						<Stack>
							<Controller
								name="eventTypeId"
								control={control}
								render={({ field }) => (
									<SelectField
										{...field}
										label="Event Type"
										placeholder="Select type"
										variant="filled"
										data={
											eventTypes
												? eventTypes?.items.map((el) => ({
													value: String(el.id),
													label: el.name,
												}))
												: []
										}
										value={String(field.value)}
										onChange={(value) => handleChange(field, value)}
									/>
								)}
							/>
						</Stack>

						<Group noWrap spacing={"lg"}>
							{/* Start Date */}
							<Stack w="100%">
								<Controller
									name="startDate"
									control={control}
									render={({ field, fieldState }) => (
										<DatePickField
											label="Start date"
											placeholder="Pick date"
											valueFormat="MMMM DD, YYYY HH:mm"
											variant="filled"
											minDate={currentDate}
											required
											classNames={{ input: "input-field" }}
											error={fieldState.error?.message}
											value={field.value ? new Date(field.value) : undefined}
											onChange={field.onChange}
										/>
									)}
								/>
							</Stack>
							{/* End Date */}
							<Stack w="100%">
								<Controller
									name="endDate"
									control={control}
									render={({ field, fieldState }) => (
										<DatePickField
											label="End date"
											placeholder="Pick date"
											valueFormat="MMMM DD, YYYY HH:mm"
											variant="filled"
											minDate={minEndDate}
											required
											classNames={{ input: "input-field" }}
											error={fieldState.error?.message}
											value={field.value ? new Date(field.value) : undefined}
											onChange={field.onChange}
										/>
									)}
								/>
							</Stack>
						</Group>
					</Stack>
				</Group>

				<Stack spacing="lg">
					<Stack>
						<Controller
							name="description"
							control={control}
							render={({ field, fieldState }) => (
								<TextareaField
									label="Description"
									placeholder="Enter event description"
									variant="filled"
									autosize
									required
									classNames={{ input: "input-field" }}
									error={fieldState.error?.message}
									{...field}
								/>
							)}
						/>
					</Stack>

					<Stack>
						<Controller
							name="notes"
							control={control}
							render={({ field, fieldState }) => (
								<TextareaField
									label="Notes"
									placeholder="Enter event notes"
									variant="filled"
									autosize
									required
									classNames={{ input: "input-field" }}
									error={fieldState.error?.message}
									{...field}
								/>
							)}
						/>
					</Stack>
				</Stack>

				<Group grow spacing={"lg"} mt={20} align={"flex-start"}>
					<Controller
						name="activationLimit"
						control={control}
						render={({ field, fieldState }) => (
							<Switch
								label="Toggle activate limit"
								error={fieldState.error?.message}
								checked={field.value}
								onChange={field.onChange}
							/>
						)}
					/>
					<Controller
						name="timeActivateLimitStart"
						control={control}
						render={({ field, fieldState }) => (
							<TimeInput
								label="Activate time start"
								disabled={!limitSwitchStatus}
								error={fieldState.error?.message}
								{...field}
							/>
						)}
					/>
					<Controller
						name="timeActivateLimitEnd"
						control={control}
						render={({ field, fieldState }) => (
							<TimeInput
								label="Activate time end"
								disabled={!limitSwitchStatus}
								error={fieldState.error?.message}
								{...field}
							/>
						)}
					/>
				</Group>

				<Group grow spacing={"lg"} mt={10} align={"flex-start"}>
					<Controller
						name="receiveLimit"
						control={control}
						render={({ field }) => (
							<Switch
								mt={20}
								label="Toggle receive limit"
								checked={field.value}
								onChange={field.onChange}
							/>
						)}
					/>
					<Controller
						name="timeReceiveLimitStart"
						control={control}
						render={({ field }) => (
							<TimeInput label="Receive time start" disabled={!receiveSwitchStatus} {...field} />
						)}
					/>
					<Controller
						name="timeReceiveLimitEnd"
						control={control}
						render={({ field, fieldState }) => (
							<TimeInput
								label="Receive time end"
								disabled={!receiveSwitchStatus}
								error={fieldState.error?.message}
								{...field}
							/>
						)}
					/>
				</Group>

				{/* TODO: Mock with user rewards limitations */}

				<Group grow spacing={"lg"} mt={10} align={"flex-start"}>
					<NumberInput
						label="Amount of rewards per user"
						description="Amount of rewards that user can collect"
						placeholder="Enter amount of rewards"
						variant="default"
						hideControls
						value={1}
						styles={inputStyles}
					/>

					<NumberInput
						label="Time gaps"
						description="Time gaps between when the user can collect again (in minutes)"
						placeholder="Enter amount of rewards"
						variant="default"
						hideControls
						value={30 * 60}
						styles={inputStyles}
					/>
				</Group>

				<CertainDays />
			</Stack>
		</Root>
	);
}

// CSS Helper

const InputCSS = css`
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 400;
	background: #fcfdfe;
	border: 1px solid #f0f1f7;
	color: #9fa2b4;
	line-height: 1.5;
	min-height: 42px;
	outline: none;
	width: 100%;
	transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out;

	&:hover,
	&:focus {
		color: #252733;
		border-color: #0095e5;
	}

	&::placeholder {
		color: rgb(75, 80, 109, 0.5);
	}
`;

const SelectField = styled(Select)`
	& .input-field {
		border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
	}
`;

const FieldCSS = css`
	flex: 1;

	& .input-field {
		${InputCSS};
	}
`;
// Styling
const Root = styled(Paper) <any>`
	margin-top: 20px;
	overflow: hidden;
`;

const TextField = styled(TextInput)`
	${FieldCSS};
	position: relative;
	.mantine-TextInput-error {
		position: absolute;
		left: 0;
		bottom: -25px;
	}
`;

const DatePickField = styled(DateTimePicker)`
	${FieldCSS};
`;

const TextareaField = styled(Textarea)`
	${FieldCSS};
`;

const inputStyles = {
    values: {
        marginLeft: -5,
    },
    value: {
        backgroundColor: '#F1F3F5',
        border: '1px solid #F1F3F5',
        borderRadius: '3px',
        color: '#868E96',
        fontSize: '12px',
        fontWeight: 400,
    },
    defaultValueRemove: {
        color: '#868E96',
        width: 18,
        height: 18,
        minHeight: 18,
        minWidth: 18,
    },
    input: {
		marginTop: 12,
        background: '#FCFDFE',
        border: '1px solid #F1F3F5',
        borderRadius: '4px',
        paddingTop: 3,
        paddingBottom: 3,
        "::placeholder": {
            color: '#9FA2B4'
        }
    }
}