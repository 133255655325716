import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Stack } from '@mantine/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMerchantNavigation } from '../../../hooks/useMerchantNavigation';
import useMerchantGame from 'features/MerchantGames/hooks/useMerchantGame';
import ContentLoader from 'components/ContentLoader';
import PageToolbar from 'components/PageToolbar';
import MerchantGameDetails from 'features/MerchantGameDetails';
import TabSections from 'features/MerchantGameDetails/components/TabSections';


export default function GameDetailsPage() {
	const { id } = useParams<{ id: string }>();
	const { id: merchantId, route } = useMerchantNavigation();

	const form = useForm({
		resolver: yupResolver(yup.object({

		}).required()),
		defaultValues: {},
	});

	const { game, isLoading } = useMerchantGame(merchantId, String(id));

	useEffect(() => {
		if (!game) return;
		form.reset({
			name: game.name
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game]);

	const onSubmit = form.handleSubmit(async () => {})

	if (isLoading) return <ContentLoader />

	return (
		<FormProvider {...form}>
			<Stack p={24} spacing={24}>
				<PageToolbar label={"Go Back"} backLink={`${route}/games`} />

				<form onSubmit={onSubmit}>
					<MerchantGameDetails />
				</form>

				<TabSections />

			</Stack>
		</FormProvider>
	);
}
