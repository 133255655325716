import { Button, Card, Group, Stack, Text } from "@mantine/core";
import Modal from "features/Modal";
import { ModalTrigger } from "features/Modal/constants";
import { Fragment, useMemo } from "react";
import ShareModal from "../components/ShareModal";
import { useModalStore } from "features/Modal/store/modal.store";
import { useSearchMerchant } from "features/MerchantSelector/hooks/useSearchMerchant";
import { useParams } from "react-router-dom";

import SharedItem from "../components/SharedItem";
import useData from "hooks/useData";
import type { TCollectableCard } from "features/PlatformCollectionCardsList/types";
import QueryKeys from "constants/query-keys";
import ContentLoader from "components/ContentLoader";

export default function SharedMerchants() {
    const { id } = useParams<{ id: string }>();

    const openModal = useModalStore(state => state.setOpen);

    const { data: card, isLoading } = useData<ApiResponse<TCollectableCard>, TCollectableCard>({
        path: `/admin/collectible-cards/${id}`,
        queryKey: [QueryKeys.PLATFORM_COLLECTABLE_CARD, { cardId: Number(id) }],
        select: (data) => data.data,
        enabled: !!id,
    });

    const { merchants } = useSearchMerchant();

    const sharedMerchants = useMemo(() => {
        return merchants.filter(merchant => card?.merchantCollectibleCards?.some(i => i.id === merchant.id))
    }, [merchants, card?.merchantCollectibleCards]);

    if (isLoading) return <ContentLoader />

    return (
        <Fragment>
            <Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
                <Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
                    <Group position="apart">
                        <Text size={16} weight={600} lh={1.5}>
                            Shared with merchants
                        </Text>
                        <Group>
                            <Button type="submit" variant="filled" h={28} px={12} py={4} color="dark.8" radius={4} onClick={() => openModal({
                                title: 'Share Collectable Card',
                                trigger: ModalTrigger.SHARE_COLLECTABLE_CARD_TO_MERCHANT,
                            })}>
                                <Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>SHARE</Text>
                            </Button>
                        </Group>
                    </Group>
                </Card.Section>
                <Card.Section inheritPadding p={0} pb={2}>
                    <Stack spacing={0}>
                        {sharedMerchants.map((item) => (
                            <SharedItem key={item.id} merchant={item} />
                        ))}

                        {sharedMerchants.length === 0 && (
                            <Group p={24} align="center" position="center">
                                <Text size={"14px"} weight={400} color="gray.6" lh={1.5}>Merchant list is empty</Text>
                            </Group>
                        )}
                    </Stack>
                </Card.Section>
            </Card>

            <Modal triggers={[ModalTrigger.SHARE_COLLECTABLE_CARD_TO_MERCHANT]}>
                <ShareModal />
            </Modal>

        </Fragment>
    )
}