import { TGame } from "features/PlatformGames/types";
import usePaginatedData from "hooks/usePaginatedData";
import { useMemo } from "react";
import { useSharingGameStore } from "store/game-sharing.store";

export default function useMerchantGames(merchant_id: string | number) {
    const syncSharingGames = useSharingGameStore(state => state.sync);
	const sharingGames = useSharingGameStore(state => state.sharedGames);

	const { items: loadedGames, ...options } = usePaginatedData<TGame>({
        path: "/admin/games",
        defaultLimit: 1000,
        queryKey: ["GAME_LIST"],
        onSuccess(data) {
            syncSharingGames(data.items?.map((item: any) => item.id) ?? []);
        },
    });
    
    const games = useMemo(() => {
		return loadedGames
            .filter((game) => sharingGames.some(i => i.game_id === game.id && i.merchant_ids.includes(Number(merchant_id))))
	}, [loadedGames, sharingGames, merchant_id]);

    return {
        games,
        ...options,
    }
}