import {Icon} from "@iconify/react"
import {Link} from "react-router-dom";
import ContentLoader from "components/ContentLoader";
import useContentList from "features/ContentList/hooks/useContentList";
import ContentList from "features/ContentList";
import { Button, Group, Stack, Text } from "@mantine/core";
import {useMerchantNavigation} from "../../../hooks/useMerchantNavigation";
import { useEventCards } from "features/EventEdit/store/event-cards.store";
import { useQueryClient } from "react-query";
import QueryKeys from "constants/query-keys";

// Page
export default function Contents() {
  const { contents, isLoading } = useContentList()
  const { route } = useMerchantNavigation()
  const resetStore = useEventCards(state => state.resetStore);
  const queryClient = useQueryClient();

  const handleCreate = async () => {
    await queryClient.invalidateQueries([QueryKeys.CARDS_FOR_EVENT]);
    resetStore();
  }

  return (
    <Stack p={'lg'} spacing={0}>

      <Group position='apart'>
      <Group position="left">
          <Text size="xl" weight="bold">
            Contents
          </Text>
        </Group>
        <Group position="right">
          <Button
            component={Link}
            to={`${route}/contents/create`}
            leftIcon={<Icon icon={"fluent:add-12-filled"} height={18} />}
            radius={"sm"}
            color={"blue"}
            variant={"filled"}
            onClick={handleCreate}
          >
            Create
          </Button>
        </Group>
      </Group>

      {!isLoading
        ? <ContentList contents={contents}/>
        : <ContentLoader />
      }
    
    </Stack>
  );
}
