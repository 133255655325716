import { Link } from 'react-router-dom';
import { Button, Card, Group, Stack, Text } from '@mantine/core';
import usePaginatedData from 'hooks/usePaginatedData';
import { TCollectableCard } from 'features/PlatformCollectionCardsList/types';
import ListItem from 'features/PlatformCollectionCardsList/components/ListItem';
import ContentLoader from 'components/ContentLoader';
import QueryKeys from 'constants/query-keys';

// Page
export default function PlatformCollectableCards() {

    const { items, isLoading } = usePaginatedData<TCollectableCard>({
        path: "/admin/collectible-cards",
        defaultLimit: 1000,
        queryKey: [QueryKeys.PLATFORM_COLLECTABLE_CARDS],
    });

    return (
        <Stack p={24} spacing={24}>
            <Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
                <Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
                    <Group position="apart">
                        <Text size={16} weight={600} lh={1.5}>
                            Collectable Cards
                        </Text>
                        <Group>
                            <Button component={Link} type="submit" variant="filled" h={28} px={12} py={4} color="dark.8" radius={4} to={`/platform/collectable-cards/create`}>
                                <Text size={"14px"} weight={500} tt={"uppercase"} lh={1.5}>
                                    ADD
                                </Text>
                            </Button>
                        </Group>
                    </Group>
                </Card.Section>
                <Card.Section inheritPadding p={0} pb={2}>
                    <Stack spacing={0}>
                        {isLoading && <ContentLoader />}

                        {items.length > 0 &&
                            items.map((item) => <ListItem card={item} key={item.id} />)}

                        {!isLoading && items.length === 0 && (
                            <Group p={24} align="center" position="center">
                                <Text size={"14px"} weight={400} color="gray.6" lh={1.5}>
                                    Collectable card list is empty
                                </Text>
                            </Group>
                        )}
                    </Stack>
                </Card.Section>
            </Card>
        </Stack>
    );
}
